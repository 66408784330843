//
// ------------------------------
// browser-help
//               this handles the styling for browser-help.html
// ------------------------------

img.title {
    text-align: center;
    margin-left: 0.2em;
    @media @wider-than-mobile {
        text-align: left;
        margin-left: 4em;
    }
}

.issues {
    width: 60%;
    margin: 2em auto 0 auto;
    @media @wider-than-mobile {
        padding: 1em 0 0 1em;
    }
}