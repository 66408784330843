
@header-bg-color: @global-color-main-grey;
@header-font-color: #fff;
@nav-bg-color: #222;

// ---------------------------
// header - this is the officical wrapper of
// 		the header, green and all!
// ---------------------------
.header {
	background: @header-bg-color;
	color: @header-font-color;
	padding: 10px 0;
	margin-bottom: 15px;

	&:after {
		.u-cf();
	}
}


// ---------------------------
// persistent content - this is content that should stay
// 		visible throughout the responsive states
// ---------------------------
.header-persistent-content {
	// mobile center this content

	width: 100%;
	margin: 0 auto;
	padding: 0 40px;

	// pull it to the left on bigger
	@media @no-mobile-menu {
		margin: 0;
		padding: 0;
	}

	a {
		color: #fff;
		text-decoration: none;
	}

	.header-name {
		text-transform: uppercase;
		font-size:2.2rem;
		font-family: arial, sans-serif;
		display:block;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		white-space: nowrap;

		@media @no-mobile-menu {
			text-align: left;
			max-width: 700px;
		}

		.landing-link {
			cursor: pointer;
		}
	}
}

.nav-back {
	.back-arrow();

	top: 7px;
	left: 10px;
	width: 40px;
}
