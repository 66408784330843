.banner {
	.global-z-index-for('lower-banner');
	.global-normal-weight-font();
	
	position: fixed;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	color: #fff;
	height: 32px;
	font-size: 12px;
	text-align: center;
	padding-top: 7px;


	.js-app-input-focused & {
		display: none;

		@media @wider-than-phablet {
			display: block;
		}
	}

	@media @wider-than-tablet {
		padding-top: 5px;
		font-size: 14px;
	}
}