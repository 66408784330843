.context-helper {

	.container();
	
	padding: 0 2px 5px;

	@media @wider-than-mobile {
		padding: 0 2px 5px;
	}

	&:after {
		.u-cf();
	}

	.bread-crumbs {
		text-indent: 20px;
		cursor: pointer;
		color: #616161;
		
		.bc-arrow {

			.iconic {
				width: 20px;
			}
			width: 15px;
			float: left;
			margin-left: -20px;
			margin-right: 15px;
			margin-top: -4px;
			
			@media @wider-than-mobile {
				margin-left: -25px;
				margin-right: 20px;
			}
		}
	}

}