.feedback-modal-container .modal-content-container {
    padding-bottom: 15px;

    .feedback-modal-background {
        background-color: @global-color-primary-light;

        margin-left: -15px;
        margin-top: -53px;
        margin-right: -15px;
        padding-top: 60px;
        padding-bottom: 8px;
        margin-bottom: 12px;
    }
}

.feedback-modal-container {
    .modal-close-icon .iconic {
        fill: #fff;
    }
}

/*********************
FEEDBACK MODAL
**********************/
.feedback-modal {
    padding-bottom: 0px;

    font-family: Helvetica;

    .modal-subheader {
        margin: 10px 5%;
        line-height: initial;
    }

    .input-area-field-wrapper {
        text-align: left;
        margin-top: 10px;
    }

    .modal-subheader {
        color: #fff;
    }

    .comment-prompt {
        color: @global-color-font;
    }

    .feedback-icon {
        margin-left: 10px;
        margin-right: 10px;
        max-width: 100px;

        &:hover,
        &.selected:hover {
            background: @global-color-primary;
            border-radius: 50%;
        }

        &.selected {
            background: @global-color-primary-dark;
            border-radius: 50%;
        }
    }

    @media @wider-than-phablet{
        form {
            min-width: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .input-area-wrapper {
        margin-bottom: 0px;
    }

    .input-area-field-wrapper.inline-input-type {
        padding-left: 0px;
        padding-bottom: 0px;
    }

    textarea {
        width: 100%;
        margin-bottom: 8px;
    }
}
