.notification {
    background: #3F5D88;
	border: 1px solid #3F5D88;
	text-align: left;
	color: #fff;
    font-size: 14px;
    .global-normal-weight-font();
	border-radius: @global-radius;
	position: relative;

	// transitions
	
	transition: opacity ease-in 200ms;

	&.ng-show {
		opacity: 1;
	}

	&.ng-hide {
		opacity: 0;
	}

    &.clickable {
        cursor: pointer;
    }

    .primary-icon-container {
        max-width: 12%;
        display: none;

        icon {
            float:left;
            padding: 12px;
        }
    }

    &.has-icon .primary-icon-container {
        display:inline-block;
    }

    &.closable.has-icon .notification-content-container {
        width: 79%
    }

    &.has-icon .notification-content-container {
        width: 88%;
    }

    &.closable .notification-content-container {
        width: 91%; 
    }
}

.notification-content {
	padding: 18px 15px;

    .has-icon & {
        padding-left: 0px;
    }

    .link {
        color: #fff;
    }
}

.notification-close {
    position: absolute;
	right: 2.6%;
	top: 50%;
	margin-top: -2%;
	cursor: pointer;

	.iconic {
		display: none;
		width: 15px;
		height: 15px;
		fill: #fff;

		&:hover {
			fill: darken(#3F5D88, 35%);
		}

		.closable & {
			display: block;
		}
	}
}

/******************
SIZING VARIANT
******************/
.notification-inline {
	display: inline-block;
	width: auto;

	.notification-content {
		padding: 1px 10px 2px;
	}

	.iconic {
		margin: 0 5px 0 0;
		width: 15px;
		height: 15px;
		position: relative;
		top: 2px;
	}
}


/******************
COLOR VARIANT
******************/
.notification {
	&.success {
		background: lighten(@global-color-primary-light, 10%);
		border: 1px solid darken(@global-color-primary-light, 10%);
		color: darken(@global-color-primary, 23%);

		.iconic {
			fill: darken(@global-color-primary, 23%);
		}

		.notification-content {
			background: @global-color-primary-light;
		}
	}
}



