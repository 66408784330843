
@shield-size : 34px;

#footer {
	position: relative;
	padding-top: 15px;

	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 15px;
		top: 0;
		background-color: @global-color-light-grey;
	}
}

.footer-content {
	.container();
	.u-cf();

    padding: 20px 10px;

	@media @wider-than-tablet {
		padding: 20px 0;
	}
}

.footer-shield {
	display: block;
	margin: 0 auto;
	width: @shield-size;
	height: @shield-size;

	* {
		fill: @global-color-main-grey;
	}

	@media @wider-than-mobile {
		float: left;
		margin: 0;
	}
}

.footer-copyright {
	font-size: 1.1rem;
	margin: 0 auto;
	text-align: center;
	color: @global-color-main-grey;

	@media @wider-than-mobile {
		float: left;
		margin: 0 0 0 10px;
		text-align: left;
	}
}

.footer-version {
	position: absolute;
	bottom: 0;
	right: 0;
	color: #f5f5f5;
}

.accepted-cards {
	display: block;
	width: 209px;
	margin: 0px auto 15px auto;

	@media @wider-than-mobile {
		float: right;
		margin: 0;
	}
	
	img {
		height: 34px;
		margin-right: 5px;

		&:last-of-type {
			margin-right: 0;
		}

		&#visa {
			width: 55px;
		}
	}
}