.bp-alert {

    display: inline-block;
    font-size: 1.4rem;
    text-align: left;
    padding: 10px;

    .iconic {
        float: left;
        margin: 5px;
        padding: 5px;
        opacity: .89;
    }

    span {
        display: block;
        margin-left: 45px;
    }

}

.bp-alert-success {
    color: #4CAF50;
    .iconic {
        fill: #4CAF50;
    }
}

.bp-alert-info {
    color: #3F51B5;
    .iconic {
        fill: #3F51B5;
    }
}

.bp-alert-warning {
    color: #FB8C00;
    .iconic {
        fill: #FB8C00;
    }
}

.bp-alert-error {
    color: #F44336;
    .iconic {
        fill: #F44336;
    }
}
