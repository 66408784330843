datepicker a, [datepicker] a, .datepicker a,
datepicker a:hover, [datepicker] a:hover, .datepicker a:hover{
	color:#333;
	text-decoration:none;
}

// this is the select that is visible from mobile devices
datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover{
	width:100%;
	overflow: hidden;
	background:none;
	color: @global-color-dark-grey;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,0.05);
	padding: 0px 5px;
	border-radius:2px;
}

datepicker, .datepicker, [datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
	.global-normal-weight-font();
	font-size: 13.5px;
	width: 100%;
	margin: 0 auto;
	float: left;
	clear: right;
	position: relative;
}

._720kb-datepicker-calendar {
	background: #fff;
	color: #333;
	min-width: 220px;
	margin: 0 auto;
	width: 100%;
	border: 1px solid #EAE9E9;
	//visibility: hidden;
	overflow:hidden;
	padding: 0 0 2% 0;
	border-radius: @global-radius;

	@media @wider-than-phablet {
		width: 80%;
	}
}

._720kb-datepicker-calendar._720kb-datepicker-open {
	visibility: visible;
}

._720kb-datepicker-calendar-header {
	text-align: center;
	font-size: 15px;
	line-height: 40px;

	padding-top: 10px;
	.iconic {
		width: 15px;
		// padding-top: 15px;
	}

}

._720kb-datepicker-calendar-header:nth-child(odd) {
	background: #fff;
}
._720kb-datepicker-calendar-header:nth-child(even) {
	background: #F4F3F3;
}

._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
	width: 15%;
	float: left;

	.js-disabled {
		visibility: hidden;
	}
}

._720kb-datepicker-calendar-header-middle {
	width: 70%;
}
._720kb-datepicker-calendar-body {
	width: 96%;
	margin: 2%;
	text-align: center;
}
._720kb-datepicker-calendar-day {
	cursor: pointer;
	font-size: 12.5px;
	width: 12.2%;
	margin:5px 1%;
	padding: 1.5% 0;
	float: left;
	border-radius: 5px;
}
._720kb-datepicker-calendar-day:hover {
	background-color: @global-color-light-grey;
}

._720kb-datepicker-calendar-day._720kb-datepicker-active {
	background-color: @global-color-secondary;
	color: #fff;
}

._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
	text-decoration:none;
	padding:10px;
	font-size: 13.5px;
	color:rgba(0, 0, 0, 0.55);
	font-weight: bold;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
._720kb-datepicker-calendar-header a:hover {
	color:rgba(0, 0, 0, 0.9);
	background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-month {
	text-transform: uppercase;
}
._720kb-datepicker-calendar-month span {
	font-size: 13px;
	margin-left:1%;
	color:rgba(0, 0, 0, 0.4);
}
._720kb-datepicker-calendar-month a span  i {
	font-style: normal;
	font-size:15px;
}
._720kb-datepicker-calendar-month a, ._720kb-datepicker-calendar-month a:hover {
	
	// currently we dont want the user to be able
	// to jump via a years dropdown
	display: none;

	padding: 3px;
	margin-left:1%;
}
._720kb-datepicker-calendar-years-pagination{
	padding:2% 0 0 0;
	float:left;
	clear: right;
	width: 100%;
}
._720kb-datepicker-calendar-years-pagination a, ._720kb-datepicker-calendar-years-pagination a:hover {
	font-size:12px;
	padding:0 7px;
	font-weight: normal;
	margin:3px 1% 0 1%;
	line-height: 20px;
	display: inline-block;
}
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
	color:rgba(0, 0, 0, 0.9);
	font-weight: 500;
	background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-years-pagination-pages a,._720kb-datepicker-calendar-years-pagination-pages a:hover{
	padding:5px 10px;
}
._720kb-datepicker-calendar-days-header{
	max-width: 100%;
	margin:0 auto;
	padding:0 2% 0 2%;
	background: #fff;
	border-bottom:1px solid #f4f4f4;
}
._720kb-datepicker-calendar-days-header div{
	width: 14.2%;
	font-weight: 500;
	font-size: 11.5px;
	padding:10px 0.5%;
	float:left;
	text-align: center;
	color:rgba(0,0,0,0.7);
}
._720kb-datepicker-calendar-days
._720kb-datepicker-default-button{
	font-size: 18.5px;
	position: relative;
	bottom:-0.5px;
}
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item{
	width:95%;
	float:none;
	margin:0 auto;
}
._720kb-datepicker-item-hidden{
	visibility:hidden;
}
._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover{
	color:rgba(0,0,0,0.2);
	background: rgba(25,2,0,0.02);
	cursor: default;
}