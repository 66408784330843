.loading-indicator {
    margin: 0 auto;
    margin-top: -5em;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid @global-color-light-grey;
    border-right: 1.1em solid @global-color-light-grey;
    border-bottom: 1.1em solid @global-color-light-grey;
    border-left: 1.1em solid @global-color-main-grey;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear;

    top: 50vh;
    @media @wider-than-tablet {
        top: 325px;
    }
}
.loading-indicator,
.loading-indicator:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
