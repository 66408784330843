@side-padding: 10px;

.landing-content {
	background-color: transparent;
	.u-cf();
}

//Form and Form Container
.landing-entry-container {
	background: @global-color-main-grey;
	color: #fff;
	padding-top: 57px; //For matching height of app navbar
	width: 100%;

	@media @wider-than-tablet {
		float:left;
		max-width: 295px;
		min-height: 650px;
		padding-top: 5%;
	}

	.landing-entry-content {
		padding: 0px @side-padding 15px;

		.input-area-wrapper.errored {
			margin-bottom: 1rem;
		}
		input {
			margin-left: 2px;
		}
	}
}

// Marketing Content
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.landing-secondary-content {
	@media @wider-than-tablet {
		max-width: 55%;
		float: right;
	}
}

.landing-info-content {
	padding: 50px @side-padding ;
	font-size: 2rem;
	color: darken(@global-color-light-grey, 40%);


	@media @wider-than-tablet {
		animation-name: fadeInDown;
		animation-fill-mode: both;
		animation-duration: 0.9s;
		animation-delay:0.2s;
		font-size: 2.4rem;
		clear:right;
		margin-top: 35%;
		padding: 50px 0;
	}

	button {
		margin-top: 15px;
		font-size: 13px;
	}

	.service-mark {
		font-size: 1.8rem;
	}
}

//Form Header/Instructions
.account-amount-entry {
	.provider {
		font-size: 22px;
		text-align: center;
		letter-spacing: 1.2px;
		line-height: 1.1;
	}
	.instructions {
		padding: 5px 0 10px 0;
		font-size: 14px;
		text-align: center;
	}
}

//Logo Container and Centering
.landing-logo {
	text-align: center;
	margin: 0 auto 5%;
	height: 164px;
	background-color: white;
	padding-top: 7px;
	white-space: nowrap;

	.provider-logo {
	  display: inline-block;
	  vertical-align: middle;
	}
}

.landing-logo:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.not-found-error-modal {
	height: 50%;
	.not-found-error-modal-content {
		padding-top: 24px;
		padding-left: 20px;
		padding-right: 20px;
		font-size: 140%;
	}
}