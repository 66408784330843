/* 
	Areas are page segments that are entirely different parts
	of the applications. For example, the Dashboard, Messages, and Settings
	are different areas.
*/

@keyframes fadeIn {
    0%      { display:block; opacity: 0; }
    100%    { opacity: 1; }
}

@keyframes fadeOut {
    0%    { opacity: 1; }
    100%      { opacity: 0; }
}

@keyframes slideLeft {
	0% { opacity: 0; transform: translate3d(100%, 0, 0); }
	100% {  opacity: 1; transform: translate3d(0%, 0, 0); }
}

@keyframes fadeOutLeft {
	0% {  opacity: 1; transform: translate3d(0, 0, 0);}
	100% {  opacity: 0; transform: translate3d(-100%, 0, 0);}
}

@keyframes slideRight {
	0% {  opacity: 0; transform: translate3d(-100%, 0, 0); }
	100% {  opacity: 1; transform: translate3d(0, 0, 0);	}
}
@keyframes fadeOutRight {
	0% {  opacity: 1; transform: translate3d(0, 0, 0); }
	100% {  opacity: 0; transform: translate3d(100%, 0, 0); }
}

@keyframes slideLeftCentered {
	0% {  opacity: 0; transform: translate3d(100%, -50%, 0); }
	100% {  opacity: 1; transform: translate3d(0%, -50%, 0); }
}

@keyframes fadeOutLeftCentered {
	0% {  opacity: 1; transform: translate3d(0, -50%, 0);}

	100% {  opacity: 0; transform: translate3d(-100%, -50%, 0);}
}

@keyframes slideRightCentered {
	0% {  opacity: 0; transform: translate3d(-100%, -50%, 0); }
	100% {  opacity: 1; transform: translate3d(0, -50%, 0);	}
}
@keyframes fadeOutRightCentered {
	0% {  opacity: 1; transform: translate3d(0, -50%, 0); }
	100% {  opacity: 0; transform: translate3d(100%, -50%, 0); }
}



.view-transition {
	backface-visibility: hidden;
   	animation-fill-mode: forwards;

	width: 100%;
	overflow-x: hidden;

	.content {
		//min-height:300px;
		padding:15px;
		// this may need to be put into a utility 
		background: @global-color-content-bg;
	}
	
	&.ng-enter {
		/* needed or the views jump on transition */
		display: none;
		animation: fadeIn 0.2s both 0.2s ease-in;
	}
    &.ng-leave {  
    	animation: fadeOut 0.2s both ease;
    }
}

.view-layout-transition {
   	animation-fill-mode: forwards;
	backface-visibility: hidden;

	&.ng-enter {
		animation: fadeIn 0.2s both 0.2s ease-in;
	}
    &.ng-leave {  
    	animation: fadeOut 0.2s both ease;
    }
}

@workflow-transition-duration: 0.4s;

.view-workflow-transition {	
	min-width: 300px;
	backface-visibility: hidden;
   	animation-fill-mode: forwards;


	&.ng-enter {
		/* needed or the views jump on transition */

		animation: slideLeft @workflow-transition-duration ease-in both 0s;
	}

    &.ng-leave {  
    	animation: fadeOutLeft @workflow-transition-duration  ease both;
    }

     .reverse-transition & {
    	&.ng-enter {
			/* needed or the views jump on transition */
			animation: slideRight @workflow-transition-duration ease-in both 0s;
		}

	    &.ng-leave {  
	    	animation: fadeOutRight @workflow-transition-duration  ease both;
	    }
    }

    // Sense we have some transition pieces that
    // are vertically centered with a transform
    // we need to account for that in our transition
    // and include that negative transform
    &.centered-content-transition {
    	&.ng-enter {
			/* needed or the views jump on transition */
			animation: slideLeftCentered @workflow-transition-duration ease-in both 0s;
		}

	    &.ng-leave {  
	    	animation: fadeOutLeftCentered @workflow-transition-duration  ease both;
	    }

	    .reverse-transition & {
	    	&.ng-enter {
				/* needed or the views jump on transition */
				animation: slideRightCentered @workflow-transition-duration ease-in both 0s;
			}

		    &.ng-leave {  
		    	animation: fadeOutRightCentered @workflow-transition-duration  ease both;
		    }
	    }
    }
}

