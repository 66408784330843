

// ---------------------------
// hamburger - we use this mixin to produce our 
//		hamburger menus. Note we require a span
//		child element.
// ---------------------------
.hamburger {

	// for clarity on which pieces of the element
	// are which, we will use these alias variables
	@bar-top: span:before;
	@bar-middle: span;
	@bar-bottom: span:after;

	// scope the menu position
	position: relative;
	cursor: pointer;

	// middle
	@{bar-middle} {
		top: 50%; 
	}

	@{bar-middle}, 
	@{bar-top},
	@{bar-bottom}{

		// transition will be needed for any 
		// animations like closing. We will add it 
		// here for easier use
		transition: all 0.3s;

		border-radius: 5px;
		height: 2px;
		width: 25px;
		background: white;
		position: absolute;
		display: block;
		content: '';
	}

	//top bar
	@{bar-top} {
		top: -8px;
	}

	// bottom bar;
	@{bar-bottom} {
		bottom: -8px; 
	}
}

.closed-hamburger {

	// for clarity on which pieces of the element
	// are which, we will use these alias variables
	@bar-top: span:before;
	@bar-middle: span;
	@bar-bottom: span:after;

	// turn top bar slant to bottom right like: \
	@{bar-top} {
		transform: rotate(45deg) translate3d(5px, 6px, 0);
	}

	@{bar-middle} {
		background: transparent;
	}

	// turn bottom bar slant to top like: /
	@{bar-bottom}{
  		transform: rotate(-45deg) translate3d(5px, -7px, 0);
	}
}



.back-arrow {

	// start with this foundation
	.hamburger();


	@{bar-middle}, 
	@{bar-top},
	@{bar-bottom}{
		width: 18px;
	}

	// for clarity on which pieces of the element
	// are which, we will use these alias variables
	@bar-top: span:before;
	@bar-middle: span;
	@bar-bottom: span:after;

	// turn top bar slant to bottom right like: \
	@{bar-top} {
		transform: rotate(40deg) translate3d(9px, 8px, 0);
	}

	@{bar-middle} {
		background: transparent;
	}

	// turn bottom bar slant to top like: /
	@{bar-bottom}{
  		transform: rotate(-40deg) translate3d(12px, -10px, 0);
  		width:17px; /* arrow is slightly off */
	}
}


.back-arrow-sml {
	// start with this foundation
	.hamburger();


	@{bar-middle}, 
	@{bar-top},
	@{bar-bottom}{
		width: 12px;
	}

	// for clarity on which pieces of the element
	// are which, we will use these alias variables
	@bar-top: span:before;
	@bar-middle: span;
	@bar-bottom: span:after;

	// turn top bar slant to bottom right like: \
	@{bar-top} {
		transform: rotate(40deg) translate3d(8px, 7px, 0);
	}

	@{bar-middle} {
		background: transparent;
	}

	// turn bottom bar slant to top like: /
	@{bar-bottom}{
  		transform: rotate(-40deg) translate3d(10px, -8px, 0);
  		width:11px; /* arrow is slightly off */
	}
}



