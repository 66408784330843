.contact-modal-container .modal-content-container {
    padding-bottom: 35px;
}

/****************
CONTACT MODAL
****************/
.contact-modal-container {

	input,
	select {
		margin-bottom: 5px;
	
		&[disabled] {
			.global-normal-weight-font();
		}
	}

	textarea {
		margin-top: 15px;
		margin-bottom: 0px;
	}

        min-height: 0px;

}

.contact-modal {
	transition: min-height ease 0.2s;
        padding-bottom: 0px;

	// workaround to remove the jumping content when transitioning to forgot password
	&.ng-hide {
		transition: none;
	}

	// give some space between the button and the forms
	@media @wider-than-phablet {
		button {
			margin-top: 20px;
		}
	}

	// we want the lastname icon to only show
	// on mobile, otherwise it is invisible
	@media @wider-than-phablet {
		[for="create-last"]{
			display: none;	
		}
		#create-last {
			text-indent: 0;
			margin-left: 0;
		}
	}

	.modal-header,
	.modal-subheader {
		margin-bottom: 0;
	}

        .info {
            color: @global-color-main-grey;
            font-size: 1.3rem;
            margin-bottom: 0;
        }

        .form-error {
            color: red;
            font-size: 1.3rem;
        }
}
