.receipt-content {

	.block-segment {
		padding: 25px;
		margin-bottom: 25px;
	}

	.receipt-payment-details {
		margin-top: 20px;
		font-size: 18px;
		color: @global-color-dark-grey;
		text-align: center;

		.receipt-payer {
			.global-normal-weight-font();
			color: #333;
		}
	}

	.provider-logo img {
		max-width: 200px;
		display: block;
		margin: 0 auto;
	}

	.receipt-payment-amount {
		text-align: center;
		font-size: 32px;
		color: #555;
	}

	.receipt-payment-line-items {
		margin-top: 30px;
		width: 100%;

		th, td {
			padding: 6px 10px;
		}

		thead tr {
			background: lighten(@global-color-light-grey, 8%);
		}

		tbody td {
			font-size: 14px;
		}

		// these are the transaction ids
		.desc span {
			color: @global-color-main-grey;
		}

		// these are negative amount actions like
		// amount declined and canceled
		.amount span {
			text-decoration: line-through;
		}

		.amount {
			white-space: nowrap; //needed to prevent negative amounts from taking more than one line
		}

		.receipt-summation {
			font-weight: bold;

			td {
				padding-top: 30px;
				color: @global-color-dark-grey;
			}
		}
	}

	@media @wider-than-tablet {
		.receipt-payment-details {
			text-align: left;
		}

		.provider-logo img {
			margin: 0;
		}
	}
}



/**************
LEFT COLUMN
**************/

#left-column-persist {
	.receipt-left-col-per .call-out {
		font-size: 25px;
		padding-bottom: 0;
	}
}
