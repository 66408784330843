a,
.link {
	color: @global-color-link;
	cursor: pointer;

    &.inline {
        white-space: nowrap;
    }
	
	&:hover {
		color: darken(@global-color-link, 5%);
	}

	&.active {
		text-decoration: underline;
	}
}

//--------------------
// Special states
//--------------------
.link-subtle-on-light {	
	color: @global-color-darkish-grey;
	text-decoration: none;	

	&:hover {
		text-decoration: underline;
	}
}

