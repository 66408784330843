.flow-link-set {

	background-color: #fff;
	padding: 30px 0;


	.flow-link {
		
		//.u-debug-border();

		display: block;
		width: 100%;
		color: darken(@global-color-light-grey, 40%);
		font-size: 1.8rem;
		text-indent: 15%;
		padding: 10px 0;
		cursor: default;
		
		transition: all ease 0.6s;

		background-color: darken(#fbfbfb, 1%);

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}

		&.current {
			background-color: #E0DFDF;
		}

		&.hidden {
			display: none;
		}

		&[disabled]{
			background-color: transparent;
			font-size:1.5rem;
			color: darken(@global-color-light-grey, 15%);
			padding: 3px;
			text-decoration: none;
			cursor: default;
		}
	}
}