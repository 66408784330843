/******************
ZERO STATE STYLING
******************/

.zero-state {
    .global-normal-weight-font();
    background: @global-color-body-bg;
    text-align: center;
    color: @global-color-main-grey;
    font-size: 1.5rem;

    .center-vertically {
        padding: 50px 5%;
    }
    
    @media @wide-enough-for-left-column {
        border: 2px solid @global-color-border;
        border-radius: @global-radius;
        padding: 0 10%;
        min-height: 550px;

        .center-vertically {
            position: relative;
            padding: 30% 10%;
        }
    }

    svg {
        width: 50px;
        height: 50px;
    }
}