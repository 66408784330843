.listing {

}

.listing-title {
	font-size: 2.5rem;
	color: darken(@global-color-light-grey, 25%);
	margin-bottom: 8px;
}

.listing-items {
	.no-list-style();
	

	.listing-item {

		.global-normal-weight-font();

		display: block;
		font-size: 1.8rem;
		line-height: 1.8rem;
		margin-bottom: 10px;
		padding-left:35px;

		@media @wider-than-mobile {
			padding-left: 25px;
		}

		.listing-item-secondary-desc {
			font-size: 1.3rem;
			color: @global-color-main-grey;
		}

		.listing-item-icon {

			svg {
				width:15px;
				height:15px;
			}

			margin-left: -25px;
			padding-right: 5px;
		}

		&, a {
			color: #616161;
		}
	}

	// default behavior for listing is large content
	// and small secondary desc. We need to reverse THAT
	// weight distinction
	&.listing-reverse-weight {
		.listing-item {
			
			font-size: 1.7rem;
			line-height: 2.7rem;
			margin-bottom: 10px;
			color: darken(@global-color-light-grey, 25%);

			.listing-item-secondary-desc {
				font-size: 2.6rem;
				color: #222;
			}
		}
	}
}

// Make the list have fluid items for
// wider form factors
.listing-items.list-items-fluid {

	&:after {
		.u-cf();
		display: inline-block;
	}

	@media @wider-than-mobile {
		.listing-item {
			float: left;
			padding-right: 15px;
			margin-left: 25px;
		}
	}

	@media @wide-enough-for-left-column {
		.listing-item {
			float: none;
			margin-left: 0;
		}
	}
}

