/*
    site tools are things that are used internally by people to
    either sale, monitor, or debug the product. We need to keep this
    as minimal as we can as to not introduce performance changes with
    the inclusion of site-tool less in our main style code.
*/


/**********************
Contrast Sliders
**********************/

.contrast-sliders {

    clear:both;
    display: none;
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;

    &:before, &:after {
        .u-cf();
    }

    
    &.js-open {
        display: block;
    }


    h6 {
        margin-bottom: 10px;
        font-weight: bold;
        a {
            font-weight: normal;
            font-size: 13px;
            margin-left: 15px;
        }
    }


    .slider-set {

        float:left;
        margin-right: 15px;
        input {
            display: block;
        }
    }
}