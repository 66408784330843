
@modal-container-side-padding: 15px;
@modal-actions-offset: 70px;

.modal {
	//background: #fff;

	.global-z-index-for('modal');

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 630px;
	min-width: 320px;
	height: 100%;

	// perspective: 1300px;
	visibility: hidden;
	backface-visibility: hidden;

	@media @wider-than-phablet {
		position: fixed;
		top: 50%;
		left: 50%;
		// width: 75%;
		height: 80%;
		max-width: 500px;

		transform: translateX(-50%) translateY(-50%);
	}

	&.modal-visible {
		visibility: visible;
	}
}

.modal-content-container {

	// we want to keep a tight hold on how wide modals
	//get on desktop so we will set the max here and reevaluate
	// max-width: 500px;

	color: #333;
	background: #fff;
	position: relative;
	border-radius: 3px;
	margin: 0 auto;
	height: 100%;
	max-height: 100%;
	overflow: auto;
	overflow-y: hidden;
	padding:15px @modal-container-side-padding @modal-actions-offset + 15px;
	text-align: center;

	transition: transform 0.2s ease-out;

	transform-style: preserve-3d;
	transform: translateY(35%) rotateX(-80deg);
	transform-origin: 0 100%;

	// todo: handle transition of opacity more
	// elegantly
	opacity: 0;

	@media @wider-than-phablet{
		max-height: 97%;
		height: auto;
		// account for all endpoints
		form {
			min-width: 80%;
			max-width: 80%;
			margin: 0 auto;
		}
	}

	.ng-scope {
		height: 90%;
	}

	/*****************
	STATES
	*****************/
	.success & {
		color: #fff;
		background-color: @global-color-primary-light;
	}

	.information & {
		color: #fff;
		background-color: @global-color-secondary;
	}

	.modal-visible & {
		transform: translateY(0%) rotateX(0deg);
		opacity: 1;
	}

	.modal-header {
		font-size: 2.5rem;
		margin-bottom: 10px;
		@media @wider-than-phablet{
			font-size: 3.2rem;
		}
	}

	.modal-subheader {
		font-size: 1.8rem;
		margin-bottom: 10px;
		@media @wider-than-phablet{
			font-size: 2.4rem;
		}
	}

	.modal-subcontent {
		font-size: 18px;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		height: 100%;

		@media @wider-than-phablet{
			height: auto;
			min-height: 100px;
			font-size: 18px;
			padding-left: 30px;
			padding-right: 30px;
			overflow-y: inherit;
		}
	}


	.button-group {
		margin-bottom: 15px;
	}

	iframe {
		width: 100%;
		height: 100%;

		@media @wider-than-phablet{
			height: 500px;
		}
	}

	iframe[seamless]{
		background-color: transparent;
		border: 0px none transparent;
		padding: 0px;
		overflow: hidden;
    }


}

.modal-close-icon {
	text-transform: uppercase;
	font-weight: 600;
	line-height: 14px;
	display: block;
	padding: 5px 10px;
	cursor: pointer;
	text-align: right;

	& .iconic {
		width:15px;
		height:15px;
		fill: #999;
	}

	.success & .iconic,
	.information & .iconic{
		fill: #fff;
	}
}



.modal-actions {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px 5px;
	height: @modal-actions-offset;
	background: #fff;

	button {
		min-width: 40%;
		margin: 0 2% 0 2%;
		padding: inherit;

		&.modal-single-button {
			min-width: 80%;
		}
	}

	@media (max-height: 375px) {
		position: initial; //when the screen gets too short (e.g. android with soft keyboard loaded), undock it from the bottom and have user scroll to it
	}
}

/*****************
SIMPLE MODAL STYLES
*****************/
.simple-modal {
	.icon-padding {
		padding: 10% 0 5%;
	}
}
