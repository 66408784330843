@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

.icon-check svg path {
	stroke-dasharray:50px, 50px; 
	stroke-dashoffset: 0px;
    -webkit-animation: checkmark .25s ease-in-out .7s backwards;
    animation: checkmark .25s ease-in-out .7s backwards
}

.icon-check svg circle {
	stroke-dasharray:240px, 240px; 
	stroke-dashoffset: 480px;
    -webkit-animation: checkmark-circle .6s ease-in-out backwards;
    animation: checkmark-circle .6s ease-in-out backwards
}


@icon-position-offset: 20px;

.iconic {
	fill: @global-color-main-grey;
}

.icon-for-input {
	// display: inline-block;
	// position: relative;
	// left: @icon-position-offset;
	// top: 3px;
	// width: @icon-position-offset;
	// height: @icon-position-offset;
	// fill: darken(@global-color-light-grey, 15%);


	.icon-active & {
		fill: @global-color-dark-grey;
	}
}

// .icon-for-input-pull-right {

// 	left: 96%;
// 	margin-left: -1 * @icon-position-offset;
// }

// .input-with-icon {
// 	margin-left: -1 * @icon-position-offset;
// 	text-indent: @icon-position-offset + 15;
// 	min-width: 96%;
// 	@media @wider-than-phablet {
// 		min-width: inherit;
// 	}
// }

// .input-with-right-icon {
// 	margin-left: -12px;
// }