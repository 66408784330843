/* Adapted from Skeleton CSS*/

// Grid Variables - Rare you should need to ever change these!
@total-columns: 12;
@column-width: 100 / @total-columns; // calculates individual column width based off of # of columns
@column-margin: 4%; // space between columns


// Grid Functions - note you can use these for any less file that
// is used below the import of the grids
.grid-column-width(@n) {
	width: @column-width * @n - (@column-margin) + (@n/3);
}
.grid-offset-length(@n) {
	margin-left: percentage((@column-width * @n  + (@n/3)) / 100);
}



/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.row:after {
	&:extend(.u-cf);
}


.column,
.columns {
	width: 100%;
	float: left;
	box-sizing: border-box;
}



@media @wider-than-phablet {
	.column,
	.columns {
		margin-left: 4%;
	}
	.column:first-child,
	.columns:first-child {
		margin-left: 0;
	}

	.one.column,
	.one.columns          { .grid-column-width(1);  }
	.two.columns          { .grid-column-width(2);  }
	.three.columns        { .grid-column-width(3);  }
	.four.columns         { .grid-column-width(4);  }
	.five.columns         { .grid-column-width(5);  }
	.six.columns          { .grid-column-width(6);  }
	.seven.columns        { .grid-column-width(7);  }
	.eight.columns        { .grid-column-width(8);  }
	.nine.columns         { .grid-column-width(9);  }
	.ten.columns          { .grid-column-width(10); }
	.eleven.columns       { .grid-column-width(11); }
	.twelve.columns       { width: 100%; margin-left: 0; }

	.one-third.column     { .grid-column-width(4);  }
	.two-thirds.column    { .grid-column-width(8);  }

	.one-half.column      { .grid-column-width(6);  }


	/* Offsets */
	.offset-by-one.column,
	.offset-by-one.columns       { .grid-offset-length(1);  }

	.offset-by-two.column,
	.offset-by-two.columns       { .grid-offset-length(2);  }

	.offset-by-three.column,
	.offset-by-three.columns     { .grid-offset-length(3);  }

	.offset-by-four.column,
	.offset-by-four.columns      { .grid-offset-length(4);  }

	.offset-by-five.column,
	.offset-by-five.columns      { .grid-offset-length(5);  }

	.offset-by-six.column,
	.offset-by-six.columns       { .grid-offset-length(6);  }

	.offset-by-seven.column,
	.offset-by-seven.columns     { .grid-offset-length(7);  }

	.offset-by-eight.column,
	.offset-by-eight.columns     { .grid-offset-length(8);  }

	.offset-by-nine.column,
	.offset-by-nine.columns      { .grid-offset-length(9);  }

	.offset-by-ten.column,
	.offset-by-ten.columns       { .grid-offset-length(10); }

	.offset-by-eleven.column,
	.offset-by-eleven.columns    { .grid-offset-length(11); }



	.offset-by-one-third.column, 
	.offset-by-one-third.columns  { .grid-offset-length(4);  }

	.offset-by-two-thirds.column, 
	.offset-by-two-thirds.columns { .grid-offset-length(8);  }



	.offset-by-one-half.column,   
	.offset-by-one-half.column   { .grid-offset-length(6);  }
}




